<template >
  <div class="rtl">
    <v-card class="mx-auto" style="margin: 10px" min-height="500">
      <v-list style="margin: 5px 20px">
        <h4 class="panelTitle" style="margin-top: 5px">امنیت</h4>
        <hr style="margin-top: 1rem;margin-bottom: 1rem">
        <v-row style="padding-right: 1rem">
          <v-col cols="3">
            <label>بررسی ورود و خروج کاربر</label>
          </v-col>
          <v-col>
            <input class="Input" v-model="userSelect">
          </v-col>
          <v-col>
            <v-btn color="success" style="font-size: 15px;padding: 3px 10px;" @click="getLoginHistory()">بررسی وضعیت</v-btn>
          </v-col>
          <v-col></v-col>
        </v-row>
        <hr style="margin-bottom: 1rem;margin-top: 1rem">
        <v-row style="padding-right: 1rem">
          <v-col>
            <label>وضعیت کیف پول ریالی تمامی کاربران</label>
          </v-col>
          <v-col>
            <v-btn v-if="allCryptoWalletStatus" color="error" style="font-size: 15px;padding: 3px 10px;" @click="TradeDissAllow(2)">غیرفعال </v-btn>
            <v-btn v-else color="success" style="font-size: 15px;padding: 3px 10px;width: 5rem" @click="TradeAllow(2)">فعال </v-btn>
          </v-col>
        </v-row>
      </v-list>
    </v-card>
    <Popup :show.sync="show">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          ورود و خروج کاربر
        </v-toolbar>
        <v-card-text>
          <slot name="body"></slot>
          <v-card>
            <v-card-title>
              لیست ورود و خروج ها
              <v-spacer></v-spacer>
              <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
            </v-card-title>
            <v-data-table :headers="headers" :items="userLoginHistory" :search="search">
              <template v-slot:item.createdAtUtc="{ item }">
                {{getPersianDate(item.createdAtUtc)}}
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </template>

    </Popup>
  </div>
</template>
<script>
import axiosApi from "@/axios";
import Vue from "vue";
import Popup from "@/components/Popup";
import getPersianDateTime from "@/plugins/getPersianDate";
export default {
  components: {
    Popup
  },
  name: "SecurityManage",
  data() {
    return {
      'newAccessLevel': '',
      'allRialWalletStatus': 0,
      'allCryptoWalletStatus': true,
      userSelect: '',
      userLoginHistory: [],
      show: false,
      search: '',
      headers: [
        {
          text: 'شناسه کاربری',
          align: 'start',
          sortable: false,
          value: 'username',
        },
        { text: 'تاریخ', value: 'createdAtUtc' },
        { text: 'آی پی', value: 'ipAddress' },
        { text: 'مرورگر', value: 'browserName' },
        { text: 'ورژن مرورگر', value: 'browserVersion' },
        { text: 'وضعیت', value: 'actionTitle' },
      ],
    };
  },
  mounted() {
    this.getAsset();
  },
  methods: {
    getAsset() {
      axiosApi().post('api/Manage/v1/Asset/Get', {
        id: 2
      })
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.allCryptoWalletStatus = data.data['tradeAllowed'];
        });
    },
    TradeDissAllow(id) {
      axiosApi().post('api/Manage/v1/Asset/TradeDisallow?AssetId=' + id)
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.getAsset();
          Vue.$toast.open({
            message: 'کیف پول ریالی با موفقیت بسته شد',
            type: 'success',
            position: 'top-right'
          });
        });
    },
    TradeAllow(id) {
      axiosApi().post('api/Manage/v1/Asset/TradeAllow?AssetId=' + id)
        .then(({ data }) => {
          if (data['isSuccess'] === true)
            this.getAsset();
          Vue.$toast.open({
            message: 'کیف پول ریالی با موفقیت باز شد',
            type: 'success',
            position: 'top-right'
          });
        });
    },
    getLoginHistory() {
      this.show = true;
      axiosApi().post('/api/Manage/v1/Report/GetLoginsList',
        {
          username: this.userSelect
        })
        .then(({ data }) => {
          this.userLoginHistory = data.data.details;
        });
    },
    getPersianDate(date) {
      return getPersianDateTime(date);
    },
  }
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #2196F3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
</style>
